import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button } from 'antd';
import { ReloadOutlined, CalendarOutlined } from '@ant-design/icons';
import moment from "moment-timezone";
import RangePickerCustom from "components/Picker/RangePickerCustom";
import ModalWindow from "components/Modal/ModalWindow";
import { getDataPickerData, translateDatesFromStringToServer } from "utils/Time";
import useWindowDimensions from "utils/Dimensions";

export function DropdownTime({ configuration, handler }) {
  const { t } = useTranslation();
  const buttonRef = useRef();
  const [selected, setSelected] = useState(configuration.text || 'time.today');
  const [dates, setDates] = useState({ dates: null, dateStrings: null });
  const { width } = useWindowDimensions();
  
  const defaultMenu = configuration.menu || ['time.today', 'time.month', 'time.year'];

  const handleOptionClick = (option) => {
    const [text, datesInterval, endpoint] = getDataPickerData(option);
    handler({ ...configuration, text, dates: datesInterval, url: endpoint, isLoading: true });
    setSelected(option);
  };

  const isDisabled = (text) => configuration.text === text;

  const rangePickerChange = (dates, dateStrings) => {
    setDates({ dates, dateStrings });
  };

  const modalConfiguration = {
    button: {
      accept: {
        text: "customize",
        handler: (modalId, callback) => {
          callback(1);
          const [text, datesInterval, endpoint] = getDataPickerData({ text: "customize", dates: translateDatesFromStringToServer(dates.dateStrings) });
          handler({ ...configuration, text, dates: datesInterval, url: endpoint, isLoading: true });
        }
      }
    },
    title: 'time.modal.title',
    body: <RangePickerCustom defaultValue={dates.dates} onChange={rangePickerChange} />
  };

  useEffect(() => {
    const header = buttonRef.current?.closest('.card-header');
    if (header && header.clientWidth < 319) {
      buttonRef.current.closest('.ant-btn').style.display = 'none';
    }
  }, [width]);

  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
      {defaultMenu.map((option, index) => {
        let textAlign = ['left', 'center', 'right'][index] || 'center';
        const disabled = isDisabled(option);
        const color = disabled ? 'black' : (selected === option ? 'blue' : 'gray');
        return (
          <div
            key={option}
            style={{ flex: 1, textAlign, cursor: disabled ? 'not-allowed' : 'pointer', padding: '0.5rem', color }}
            onClick={() => !disabled && handleOptionClick(option)}
          >
            {t(option)}
          </div>
        );
      })}
      {!configuration.notCustom && (
        <ModalWindow configuration={modalConfiguration} caller={
          <div style={{ cursor: 'pointer', color: 'gray', padding: '0.5rem' }}>{t("customize")}</div>
        } />
      )}
    </div>
  );
}

export default function TimeMenu({ configuration, handler }) {
  const button = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    const elem = button.current?.querySelector('.ant-btn-group button:first-child');
    elem?.replaceWith(elem.cloneNode(true));
  }, [t(configuration.text)]);

  const reload = (e) => {
    e.target.closest('.no-focus')?.blur();
    handler({ ...configuration, isLoading: true });
  };

  return (
    <div className="time-card-wrapper d-flex align-content-center" ref={button}>
      <DropdownTime configuration={configuration} handler={handler} />
      {!configuration.notRefresh && (
        <Button className="no-focus" icon={<ReloadOutlined />} size='small' onClick={reload} style={{ marginLeft: "-1px" }} />
      )}
    </div>
  );
}
