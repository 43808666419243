import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {SyncSummary} from "components/Summary/SyncSummary";
import {Feather} from "react-feather";
import Skeleton from "react-loading-skeleton";
import {PlantsTable} from "components/Table/PlantsTable";
import BreadcrumbConstructor from "components/Breadcrumb";
import {CardPlotEvents} from "../../components/Cards/CardPlotEvents";
import {_ALERTS} from "../../utils/Types";
import {CardPlotCircleEvents} from "../../components/Cards/CardPlotCircleEvents";
import config from "/app/src/config.js";

const isMobileOnly = config.mbl_version;

export default function PageThermal({...props}) {
    const {t} = useTranslation();
    const sectionId = `dashboard-${props.caller}`;
    //to update a number on sync...
    const [syncPlantNumber, setSyncPlantNumber] = useState(null);
    //set number to syncPlantNumber
    const setPlantSyncCallback = () => {
        setSyncPlantNumber(<Skeleton style={{width: "50px"}} />);
    }

    return (
        <>
            { !isMobileOnly &&
                <div className="content-header row">
                    <BreadcrumbConstructor crumbs={[{id: 'dashboard'} , {id: 'plants'}, { id: props.caller, disabled: true }]}/>
                </div>
            }
            <div className="content-body mb-1">
                <section id={sectionId}>
                    { /* first row */ }
                    { !isMobileOnly &&
                        <div className="row match-height">
                            { /* first row: second group */ }
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                <SyncSummary number={syncPlantNumber} caller={props.caller} type="1" title={t('plants')} icon={ <Feather />} text={t('analytics.synchronized_plants')}/>
                            </div>
                            { /* first row: plot */ }
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 hide-450">
                                <CardPlotEvents caller={props.caller} type={_ALERTS} />
                            </div>
                            <div className="col-12 show-450">
                                <CardPlotCircleEvents caller={props.caller} type={_ALERTS} />
                            </div>
                        </div>
                    } { /* mobile section header summary */ }
                    { isMobileOnly &&
                        <SyncSummary number={<Skeleton style={{width: "50px"}} />} caller={props.caller} type="1" text={ t('') } style={{backgroundColor: "rgba(237, 237, 237, 0.68)", borderBottom: "1px solid rgb(195, 194, 194)"}} />
                    } { /* tablet with searcher */ }
                    <div className={`row match-height ${isMobileOnly && "row-mbl match-height-mbl"}`}>
                        <div className={`col-12 ${isMobileOnly && "col-mbl"}`}>
                            <PlantsTable caller={props.caller} numberHandler={setPlantSyncCallback}/>
                        </div>
                    </div>
                </section>
            </div>
        </>);
};