/*import React, {useRef, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import { Menu, Dropdown, Button } from 'antd';
import {CalendarOutlined, ReloadOutlined} from '@ant-design/icons';
import moment from "moment-timezone";
import {geTimeByTimeLine, getTimeByTextLink, isThisMonth, isThisYear, isToday} from "../../utils/Time";

//drop down list
export function DropdownTime({...props}) {
    //translations
    const {t} = useTranslation();
    const configuration = props.configuration;
    //set default text to to menu
    let buttonText = t(configuration.text);
    //set default menu
    let defaultMenu = (configuration.menu)?configuration.menu:['time.today','time.month','time.year'];
    //if is set timeline
    if(configuration.timeLine) {
        //set endpoint
        if(configuration.timeLine.text === 'time.today' && isToday(geTimeByTimeLine(configuration.timeLine))) {
            buttonText = t(configuration.timeLine.text);
        } else if(configuration.timeLine.text === 'time.month' && isThisMonth(geTimeByTimeLine(configuration.timeLine))) {
            buttonText = t(configuration.timeLine.text);
        } else if(configuration.timeLine.text === 'time.year' && isThisYear(geTimeByTimeLine(configuration.timeLine))) {
            buttonText = t(configuration.timeLine.text);
        } 
        //else  {
          
          //  buttonText = t('custom');
        //}
    }

    //when element on list is clicked
    const handleMenuClick = (e) => {
        //get selected text
        const text = e.domEvent.target.closest('.ant-dropdown-menu-item').getAttribute('data-text');
        const timeLine = {text: text, clicks: 0 };
        const loaders = { plot: false, request: true, error: false };
        //set endpoint
        if(text === 'time.today') {
            props.handler({ ...configuration,
                text: text, url: `time.today?day=${moment().format('YYYY-MM-DD')}`,
                timeLine: timeLine, loaders: loaders
            });
        } else if(text === 'time.month') {
            props.handler({ ...configuration,
                text: text, url: `time.month?year=${moment().format('YYYY')}&month=${moment().format('MM')}`,
                timeLine: timeLine, loaders: loaders
            });
        } else if(text === 'time.year') {
            props.handler({ ...configuration,
                text: text, url: `time.year?year=${moment().format('YYYY')}`,
                timeLine: timeLine, loaders: loaders
            });
        }
    }
    //check if element is disabled
    const isDisabled = (text) => {
        if(configuration.timeLine && configuration.timeLine.clicks !== 0) { return false;}
        return configuration.text === text;
    }

    //create a menu
    const menu = (
        <Menu>
            {
                defaultMenu.map((text, index) => (
                    <Menu.Item key={text} data-text={text} onClick={handleMenuClick} disabled={isDisabled(text)} className={'dropdown-item-small'}>
                        { t(text) }
                    </Menu.Item>
                ))
            }
        </Menu>
    );

    return (
        <Dropdown.Button disabled={defaultMenu.length === 1} key={buttonText} size='small' trigger='click' overlay={menu}
                         placement="bottomRight" icon={<CalendarOutlined />} >
            { buttonText }
        </Dropdown.Button>
    )
}

export default function TimeIntervalsMenu({...props}) {
    //reference to delete events of button
    const button = useRef();
    const configuration = props.configuration;
    //translations
    const {t} = useTranslation();
    //set use effect dependency
    const useEffectDependency = t(configuration.text);
    //disable all event on button
    useEffect(() => {
        const elem = button.current.querySelector('.ant-btn-group button:first-child');
        if(elem) { elem.replaceWith(elem.cloneNode(true)); }
    }, [useEffectDependency]);

    //reload button
    const reload = (e) => {
        //remove blur on click button
        e.target.closest('.no-focus').blur();
        //handler to reload
        props.handler({ ...configuration, loaders: { plot: false, request: true, error: false } });
    }

    return (
        <div className="time-card-wrapper d-flex align-content-center" ref={button}>
            { !props.configuration.timeLine.disabledHistory && <DropdownTime {...props} /> }
            <div style={{marginLeft: "-1px"}}>
                
            </div>
        </div>
    )

}*/

import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import moment from "moment-timezone";
import { geTimeByTimeLine, isThisMonth, isThisYear, isToday } from "../../utils/Time";

//
// Componente que antes mostraba un Dropdown, ahora muestra 3 opciones fijas:
// time.today, time.month, time.year (o las que definas en configuration.menu)
//
export function DropdownTime({ configuration, handler }) {
  const { t } = useTranslation();
  // Opciones por defecto
  const defaultMenu = configuration.menu || ['time.today', 'time.month', 'time.year'];
  
  // Estado para saber cuál está seleccionada (por defecto, la de configuration.text)
  const [selected, setSelected] = useState(configuration.text || defaultMenu[0]);

  // Lógica que antes estaba en handleMenuClick: según la opción clicada, arma la URL y llama al handler
  const handleOptionClick = (option) => {
    const text = option;
    const timeLine = { text, clicks: 0 };
    const loaders = { plot: false, request: true, error: false };

    if (text === 'time.today') {
      handler({
        ...configuration,
        text: text,
        url: `time.today?day=${moment().format('YYYY-MM-DD')}`,
        timeLine,
        loaders
      });
    } else if (text === 'time.month') {
      handler({
        ...configuration,
        text: text,
        url: `time.month?year=${moment().format('YYYY')}&month=${moment().format('MM')}`,
        timeLine,
        loaders
      });
    } else if (text === 'time.year') {
      handler({
        ...configuration,
        text: text,
        url: `time.year?year=${moment().format('YYYY')}`,
        timeLine,
        loaders
      });
    }

    // Guarda la opción seleccionada para resaltar
    setSelected(option);
  };

  // Lógica para saber si una opción está "deshabilitada"
  const isDisabled = (text) => {
    if (configuration.timeLine && configuration.timeLine.clicks !== 0) {
      return false; // si hay clicks en timeLine, no se deshabilita
    }
    // Por defecto, se deshabilita si es la misma que ya está seleccionada
    return configuration.text === text;
  };

  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
      {
        defaultMenu.map((option, index) => {
          // Alineamos la primera a la izquierda, la segunda al centro y la tercera a la derecha
          let textAlign = 'center';
          if (defaultMenu.length === 3) {
            if (index === 0) textAlign = 'left';
            else if (index === 2) textAlign = 'right';
          }
          // Definimos estilos según si está deshabilitada o seleccionada
          const disabled = isDisabled(option);
          const color = disabled ? 'black' : (selected === option ? 'black' : 'gray');
          
          return (
            <div
              key={option}
              style={{
                flex: 1,
                textAlign,
                cursor: disabled ? 'not-allowed' : 'pointer',
                padding: '0.5rem',
                color
              }}
              onClick={() => !disabled && handleOptionClick(option)}
            >
              {t(option)}
            </div>
          );
        })
      }
    </div>
  );
}

//
// Componente principal que incluye el TimeLine (antes se llamaba TimeIntervalsMenu)
//
export default function TimeIntervalsMenu({ configuration, handler }) {
  const button = useRef();
  const { t } = useTranslation();

 
  const useEffectDependency = t(configuration.text);

  useEffect(() => {
    
    
    if (button.current) {
      const elem = button.current.querySelector('.ant-btn-group button:first-child');
      if (elem) {
        elem.replaceWith(elem.cloneNode(true));
      }
    }
  }, [useEffectDependency]);

 
  const reload = (e) => {
    e.target.closest('.no-focus')?.blur();
    handler({
      ...configuration,
      loaders: { plot: false, request: true, error: false }
    });
  };

  let refresh = (
    <div style={{ marginLeft: "-1px" }}>
      {/*<Button
        className="no-focus"
        icon={<ReloadOutlined />}
        size='small'
        onClick={reload}
      /> */}
    </div>
  );

 
  if (configuration.timeLine?.disabledHistory) {
    refresh = null;
  }

  return (
    <div
      className="time-card-wrapper d-flex align-content-center"
      ref={button}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      {/* Solo se muestra la barra de opciones si no está deshabilitada */}
      { !configuration.timeLine?.disabledHistory && (
          <DropdownTime configuration={configuration} handler={handler} />
      )}
      { refresh }
    </div>
  );
}
