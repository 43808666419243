//COLUMNS
import {Tooltip} from "antd";
import i18next from "i18next";
import {
    getIconByGlobalPlantStatus,
    getIconByPlantSecond_reading,IconSecondR,
    getIconByPlantStatus, getIconComponentByCaller,
    getProductionColumnValueByCaller,
    isSolarPhotovoltaicByCaller,
    isSolarThermalByCaller,
    isThermalByCaller,
    isWaterByCaller
} from "utils/Types";
import {getTimeDateMonthText, getTimeDateText, getTimeDateTextFull, getTimeHourText} from "utils/Time";
import {getEcoSavings, getEnergyFormat, getWaterFormat} from "../../utils/Formats";
import {Trash} from "react-feather";
import React from "react";
import {HiExternalLink} from "react-icons/hi";
import _ from "lodash";
import {BsThermometerSnow, BsThermometerSun} from "react-icons/bs";
import {RiContrastDrop2Fill /*, RiHandCoinLine*/} from "react-icons/ri";
import config from "/app/src/config.js";
import { AiOutlineNotification } from "react-icons/ai";
// import {MdCloudOff} from "react-icons/md";

const isMobileOnly = config.mbl_version;

export function getColumnTableProduction(config, column) {
    return {
        datefull: {
            name: (function(){
                if (config.time === 'time.hourly') {
                    return i18next.t('datetime')
                } else if(config.time === 'time.daily') {
                    return i18next.t('date');
                } else if(config.time === 'time.monthly') {
                    return i18next.t('month');
                }
            }()),
            sortable: true,
            selector: row => {
                if(config.time === 'time.hourly') {
                    return getTimeDateTextFull(row.plant_date)
                } else if(config.time === 'time.daily') {
                    return getTimeDateText(row.plant_date)
                } else if(config.time === 'time.monthly') {
                    return getTimeDateMonthText(row.plant_date)
                }
            },
            width: (() => {
                if(config.time === 'time.hourly') {
                    return '175px !important';
                } else if(config.time === 'time.monthly') {
                    if (isMobileOnly) {
                        return undefined
                    }
                    return '90px !important';
                }
                return '125px !important';
            })(),
            sortField: 'plant_date'
        },
        date: {
            name: i18next.t('date'),
            sortable: true,
            selector: row => {

                return getTimeDateText(row.plant_date)
            },
            width: (config.time === 'time.hourly')?'120px !important':'115px !important',
            sortField: 'plant_date'
        },
        time: {
            name: i18next.t('time.hour'),
            sortable: true,
            selector: row => {
                return getTimeHourText(row.plant_date);
            },
            width: (config.time === 'time.hourly')?'90px !important':'90px !important',
            sortField: 'plant_date',
            center: true
        },
        status: {
            name: (function () {
                return (
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                        
                        <AiOutlineNotification size={20} />
                        {/* Número pequeño (badge) */}
                        <span
                            style={{
                                position: 'absolute',
                                bottom: -1, // Ajusta la posición vertical
                                right: -10, // Ajusta la posición horizontal
                                //backgroundColor: 'grey',
                                color: 'grey',
                                borderRadius: '50%',
                                padding: '1px 4px',
                                fontSize: '10px',
                                lineHeight: '1',
                                fontWeight: 'bold',
                            }}
                        >
                            1
                        </span>
                    </div>
                );
            })(),
            selector: row => getIconByPlantStatus({ ...config, hideEventOk: true }, row.plant_alert),
            //sortable: true,
            width: '55px !important',
            style: {
                paddingLeft: "0px !important",
                paddingRight: "0px !important",
            },
            sortField: 'plant_alert',
            center: true
        },
        co2_savings: (isMobileOnly && (isSolarThermalByCaller(config) || isSolarPhotovoltaicByCaller(config)))?{
            width: '0px !important',
            minWidth: '0px !important',
            maxWidth: '0px !important',
            style: {
                paddingLeft: "0px !important",
                paddingRight: "0px !important",
            }
        }:{
            name: i18next.t('plant.savings'),
            right: true,
            sortable: true,
            width: undefined,
            style: {
                paddingLeft: "2px !important",
                paddingRight: (() => {
                    if(config.time === 'time.monthly') {
                        return "20px !important"
                    }
                    return "2px !important"
                })(),
            },
            selector: row => {
                return getProductionColumnValueByCaller(config, row.plant_co2_savings).toFixed(2) + 'KgCO₂';
            },
            sortField: 'plant_co2_savings'
        },
        eco_savings: (isMobileOnly && (isSolarThermalByCaller(config) || isSolarPhotovoltaicByCaller(config)))?{
            width: '0px !important',
            minWidth: '0px !important',
            maxWidth: '0px !important',
            style: {
                paddingLeft: "0px !important",
                paddingRight: "0px !important",
            }
        }:{
            name: i18next.t('plant.savings'),
            sortable: true,
            right: true,
            width: undefined,
            style: {
                paddingLeft: (() => {
                    if (isMobileOnly && (isSolarThermalByCaller(config) || isSolarPhotovoltaicByCaller(config))) {
                        return "5px !important";
                    }
                    return "2px !important";
                }),
                paddingRight: (() => {
                    if(config.time === 'time.monthly' && isWaterByCaller(config)) {
                        return '20px !important';
                    }

                    return "2px !important";
                })()
            },
            selector: row => {
                if (isMobileOnly && (isSolarThermalByCaller(config) || isSolarPhotovoltaicByCaller(config))) {
                    return <>
                        <div>{`${row.plant_price_savings.toFixed(2)}€`}</div>
                        <div>{`${row.plant_co2_savings.toFixed(2)}KgCO₂`}</div>
                    </>
                }
                return row.plant_price_savings.toFixed(2) + '€';
            },
            sortField: 'plant_price_savings'
        },
        production: {
            name:  i18next.t('value'),
            sortable: true,
            width: undefined,
            style: {
                paddingLeft: "2px !important",
                paddingRight: (() => {
                    if(config.time === 'time.monthly') {
                        return "20px !important";
                    }
                    return "2px !important"
                })
            },
            right: !isMobileOnly || isWaterByCaller(config) || isThermalByCaller(config),
            selector: row => {
               
                if(isThermalByCaller(config)) {
                    return `${row.plant_energy}ºC`;
                } else if(isWaterByCaller(config)) {
                    return `${row.plant_energy}L`;
                }
                const value = getProductionColumnValueByCaller(config, row.plant_energy).toFixed(0) + 'kWh';

                // if(isMobileOnly) {
                //     return <div style={{pointerEvents: "none"}}>
                //         <div style={{pointerEvents: "none"}}>{value}</div>
                //         <div style={{pointerEvents: "none", marginTop: "3px", display: "flex", alignItems: "center", fontSize: '9px'}}>
                //             <RiHandCoinLine size={14} />&nbsp;
                //             { (() => {
                //                 let val = '??';
                //                 if (config.time === 'time.hourly') {
                //                     val = row.plant_price_saving.toFixed(2);
                //                 } else if(config.time === 'time.daily') {
                //                     val = row.plant_price_savings.toFixed(2);
                //                 } else if(config.time === 'time.monthly') {
                //                     val = row.plant_price_savings.toFixed(2);
                //                 }
                //                 val = 107;
                //                 return `${val}€`
                //             })()}
                //             <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                //             { /* co2 savings*/}
                //             <MdCloudOff size={14} />&nbsp;
                //             { (() => {
                //                 let val = '??';
                //                 if (config.time === 'time.hourly') {
                //                     val = row.plant_c02_saving.toFixed(2);
                //                 } else if(config.time === 'time.daily') {
                //                     val = row.plant_co2_savings.toFixed(2);
                //                 } else if(config.time === 'time.monthly') {
                //                     val = row.plant_co2_savings.toFixed(2);
                //                 }
                //                 return `${val}KgCO₂`
                //             })()}
                //         </div>
                //     </div>
                // }
                return value;
            }, sortField: 'plant_energy'
        },
        second_reading: {
            name: (function () {
                return (
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                        
                        <AiOutlineNotification size={20} />
                        {/* Número pequeño (badge) */}
                        <span
                            style={{
                                position: 'absolute',
                                bottom: -1, // Ajusta la posición vertical
                                right: -10, // Ajusta la posición horizontal
                                //backgroundColor: 'grey',
                                color: 'grey',
                                borderRadius: '50%',
                                padding: '1px 4px',
                                fontSize: '10px',
                                lineHeight: '1',
                                fontWeight: 'bold',
                            }}
                        >
                            2
                        </span>
                    </div>
                );
            })(),
            sortable: true,
            selector: (row) => row.second_reading === 2 ? IconSecondR() : getIconByPlantSecond_reading({ ...config, hideEventOk: true }, row.second_reading),
            width: '55px',
            style: {
                paddingLeft: '0px',
                paddingRight: '0px',
                textAlign: 'center',
            },
            sortField: 'second_reading_value',
        }
        

    }[column];
}

export function getGlobalStatusColumn(config) {
    return {
        selector: row => getIconByGlobalPlantStatus(config, row),
        sortable: true,
        width: '55px',
        sortField: 'plant_daily_alert_id',
        center: true,
        style: {
            paddingLeft: "0px !important", paddingRight: "0px !important",
        }
    }
}

export function getDailyStatusColumn(config) {
    return {
        name: <Tooltip placement="topLeft" title={i18next.t('plant.daily_status_extended')} overlayStyle={{fontSize: '13px'}}>
            {i18next.t('plant.daily_status')}
        </Tooltip>,
        selector: row => getIconByPlantStatus(config, row.plant_daily_alert_id),
        sortable: true,
        width: '80px',
        sortField: 'plant_daily_alert_id',
        center: true
    }
}

export function getHourlyStatusColumn(config) {
    return {
        name: <Tooltip placement="topLeft" title={i18next.t('plant.hourly_status_extended')} overlayStyle={{fontSize: '13px'}}>
            {i18next.t('plant.hourly_status')}
        </Tooltip>,
        selector: row => getIconByPlantStatus(config, row.plant_hourly_alert_id),
        sortable: true,
        width: '80px',
        sortField: 'plant_hourly_alert_id',
        center: true
    }
}

export function getShowColumn(configuration, actions) {
    let baseStyle = { paddingLeft: "5px !important", paddingRight: "0px !important" }
    if(isMobileOnly) { baseStyle = {...baseStyle, alignItems: "normal !important" }}

    return {
        sortable: false,
        style: baseStyle,
        selector: row => {
            return <HiExternalLink style={{color: "#5f5f5f", cursor: "pointer"}} size={"1.5em"} onClick={() => actions.showHandler(row) }/>;
        },
        width: '25px !important',
        center: true
    }
}

export function getNameColumn(configuration, actions) {
    let baseStyle = {
        paddingLeft: "10px !important",
        minWidth: "200px !important"
    }
    if(isMobileOnly) {
        baseStyle = {
            ...baseStyle,
            paddingLeft: "10px !important",
            wordBreak: "break-all !important",
            minWidth: "unset !important"
        };
    }

    return {
        name: i18next.t('name'),
        style: baseStyle,
        sortable: true,
        grow: 1,
        selector: (row) => {
            if(isMobileOnly) {
                return <div style={{pointerEvents: "none"}}>
                    <div style={{pointerEvents: "none"}}>{row.plant_name}</div>
                    <div style={{pointerEvents: "none", fontSize: "12px", color: "dimgrey"}}>{row.plant_town_name}</div>
                    <div style={{pointerEvents: "none", fontSize: "13px", marginTop: "3px", display: "flex", alignItems: "center"}}>
                        { /* performance for solar thermal and pv */ }
                        {(isSolarPhotovoltaicByCaller(configuration) || isSolarThermalByCaller(configuration)) &&
                            <>
                                <div style={{pointerEvents: "none", width: "60px", display: "flex", alignItems: "center"}}>
                                    <div style={{pointerEvents: "none", width: "15px"}}>
                                        η:&nbsp;
                                    </div>
                                    <div style={{pointerEvents: "none"}}>
                                        {`${_.get(row, 'last_30_plant_performance', 0).toFixed(0)}%`}
                                    </div>
                                </div>
                                <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                            </>
                        }
                        { /* min temp for thermal */ }
                        {isThermalByCaller(configuration) && <>
                            <div style={{pointerEvents: "none", width: "60px", display: "flex", alignItems: "center"}}>
                                <div style={{pointerEvents: "none"}}>
                                    <BsThermometerSnow size={'15px'} />&nbsp;
                                </div>
                                <div style={{pointerEvents: "none"}}>
                                    {`${row.min_value}ºC`}
                                </div>
                            </div>
                            <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                        </>}
                        { /* current lecture for each plant type */ }
                        <div style={{pointerEvents: "none", display: "flex", alignItems: "center"}}>
                            {getIconComponentByCaller(configuration, {size: 15, style: {marginRight: "5px"}})}
                            {(() => {
                                if (isSolarThermalByCaller(configuration)) {
                                    return getEnergyFormat(row.last_production_lecture * 1000);
                                } else if (isSolarPhotovoltaicByCaller(configuration)) {
                                    return getEnergyFormat(row.last_production_lecture);
                                } else if (isWaterByCaller(configuration)) {
                                    return getWaterFormat(row.last_production_lecture) + '/h';
                                } else if (isThermalByCaller(configuration)) {
                                    if (!row.last_production_lecture) {
                                        return '??ºC';
                                    }
                                    return `${row.last_production_lecture}ºC`;
                                }
                            })()}
                        </div>

                        { /* max value for thermal or water */ }
                        {(isThermalByCaller(configuration) || isWaterByCaller(configuration)) && <>
                            <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                            <div style={{pointerEvents: "none", display: "flex", alignItems: "center"}}>
                                {(() => {
                                    if (isWaterByCaller(configuration)) {
                                        return <><RiContrastDrop2Fill size={'15px'}/>&nbsp;{`${getWaterFormat(row.max_value)}/h`}</>;
                                    } else if (isThermalByCaller(configuration)) {
                                        return <><BsThermometerSun size={'15px'}/>&nbsp;{`${row.max_value}ºC`}</>;
                                    }
                                })()}
                            </div>
                        </>}
                    </div>
                </div>
            } else {
                return row.plant_name
            }
        }, sortField: 'plant_name', wrap: true
    }
}

export function getLocationColumn() {
    return isMobileOnly?{
        width: '0px !important',
        minWidth: '0px !important',
        maxWidth: '0px !important',
        style: {
            paddingLeft: "0px !important",
            paddingRight: "0px !important",
        }
    }:{
        name: i18next.t('location'),
        sortable: true,
        selector: row => row.plant_town_name,
        sortField: 'plant_name',
        style: {
            minWidth: "250px !important"
        }
    }
}

export function getNameFullColumn(configuration, actions) {
    return {
        name: i18next.t('name'),
        sortable: true,
        selector: row => row.plant_name_full,
        sortField: 'plant_name_full',
        minWidth: "500px !important"
    }
}

export function getAddressColumn() {
    return {
        name: i18next.t('address'),
        sortable: true,
        selector: row => row.plant_address_all,
        sortField: 'plant_address_all'
    }
}

export function getProductionColumn(configuration) {
    return isMobileOnly?{
        width: '0px !important',
        minWidth: '0px !important',
        maxWidth: '0px !important',
        style: {
            paddingLeft: "0px !important",
            paddingRight: "0px !important",
        }
    }:{
        name: (function(){
            if (isWaterByCaller(configuration)) {
                return <Tooltip placement="topLeft" title={i18next.t('last_reading')} overlayStyle={{fontSize: '13px'}}>
                    {`${i18next.t('last_female')} *`}
                </Tooltip>;
            } else if(isSolarPhotovoltaicByCaller(configuration)) {
                return <Tooltip placement="topLeft" title={i18next.t('last_reading')} overlayStyle={{fontSize: '13px'}}>
                    {`${i18next.t('plant.production')} *`}
                </Tooltip>;
            } else if(isSolarThermalByCaller(configuration)) {
                return <Tooltip placement="topLeft" title={i18next.t('last_reading')} overlayStyle={{fontSize: '13px'}}>
                    {`${i18next.t('plant.production')} *`}
                </Tooltip>;
            } else if(isThermalByCaller(configuration)) {
            return <Tooltip placement="topLeft" title={i18next.t('last_reading')} overlayStyle={{fontSize: '13px'}}>
                    {`${i18next.t('last_female')} *`}
                </Tooltip>;
            }
        }()),
        sortable: true,
        selector: row => {
            if (isSolarThermalByCaller(configuration)) {
                return getEnergyFormat(row.last_production_lecture * 1000);
            } else if (isSolarPhotovoltaicByCaller(configuration)) {
                return getEnergyFormat(row.last_production_lecture);
            } else if(isWaterByCaller(configuration)) {
                return getWaterFormat(row.last_production_lecture) + '/h';
            } else if(isThermalByCaller(configuration)) {
                if(!row.last_production_lecture) {
                    return '??';
                }
                return `${row.last_production_lecture}ºC`;
            }
        },
        width: (function(){
            if(isThermalByCaller(configuration)) {
                return '110px !important';
            }
            return '170px !important';
        }()),
        right: true,
        sortField: 'last_production_lecture'
    }
}

export function getMinColumn(configuration) {
    return isMobileOnly?{
        width: '0px !important',
        minWidth: '0px !important',
        maxWidth: '0px !important',
        style: {
            paddingLeft: "0px !important",
            paddingRight: "0px !important",
        }
    }:{
        name: (function(){
            if (isWaterByCaller(configuration)) {
                return `${i18next.t('minimum')}`;
            } else if(isThermalByCaller(configuration)) {
                return `${i18next.t('minimum_female')}`;
            }
        }()),
        sortable: true,
        selector: row => {
            return `${row.min_value}ºC`;
        },
        right: true,
        width: (function(){
            if (isWaterByCaller(configuration)) {
                return '110px !important';
            }
            return '110px !important';
        }()),
        sortField: 'min_value',
    }
}

export function getMaxColumn(configuration) {
    return isMobileOnly?{
        width: '0px !important',
        minWidth: '0px !important',
        maxWidth: '0px !important',
        style: {
            paddingLeft: "0px !important",
            paddingRight: "0px !important",
        }
    }:{
        name: (function(){
            if (isWaterByCaller(configuration)) {
                return `${i18next.t('maximum')}`;
            } else if(isThermalByCaller(configuration)) {
                return `${i18next.t('maximum_female')}`;
            }
        }()),
        sortable: true,
        selector: row => {
            if (isWaterByCaller(configuration)) {
                return `${getWaterFormat(row.max_value)}/h`;
            } else if(isThermalByCaller(configuration)) {
                return `${row.max_value}ºC`;
            }
        },
        right: true,
        width: (function(){
            if (isWaterByCaller(configuration)) {
                return '110px !important';
            }
            return '110px !important';
        }()),
        sortField: 'max_value',
    }
}

export function getSinceColumn(configuration) {
    return {
        name: i18next.t('synced_since'),
        sortable: true,
        selector: row => {
            if(!row.sync_date) { return '-'; }
            return getTimeDateText(row.sync_date)
        },
        width: '120px !important',
        sortField: 'sync_date'
    }
}

export function getActionsColumn(configuration, actions) {
    let baseStyle = {
        paddingLeft: "10px !important",
        paddingRight: "10px !important",
    }
    if(isMobileOnly) {
        baseStyle = {
            ...baseStyle,
            paddingLeft: "0px !important"
        };
    }

    return isMobileOnly?{
        width: '0px !important',
        minWidth: '0px !important',
        maxWidth: '0px !important',
        style: {
            paddingLeft: "0px !important",
            paddingRight: "0px !important",
        }
    }:{
        sortable: false,
        style: baseStyle,
        selector: row => {
            return <Trash style={{color: "#5f5f5f", cursor: "pointer"}} size={"1.5em"} onClick={() =>  actions.trashHandler(row) } />;
        },
        width: (isMobileOnly)?'30px !important':'40px !important',
        center: true
    }
}

export function getEcoColumn(configuration) {
    return {
        name: (function(){
            if (isWaterByCaller(configuration)) {
                return <Tooltip placement="topLeft" title={i18next.t('last_reading')} overlayStyle={{fontSize: '13px'}}>
                    {`${i18next.t('price')} *`}
                </Tooltip>;
            }
            return <Tooltip placement="topLeft" title={i18next.t('last_reading')} overlayStyle={{fontSize: '13px'}}>
                {`${i18next.t('plant.savings')} *`}
            </Tooltip>;
        }()),
        sortable: true,
        selector: row => getEcoSavings(row.last_eco_lecture),
        right: true,
        width: '110px !important',
        sortField: 'last_eco_lecture'
    }
}


export function getPerformanceColumn(configuration) {
    return isMobileOnly?{
        width: '0px !important',
        minWidth: '0px !important',
        maxWidth: '0px !important',
        style: {
            paddingLeft: "0px !important",
            paddingRight: "0px !important",
        }
    }:{
        name: <span style={{textTransform: "lowercase", fontSize: '16px'}}>η</span>,
        sortable: true,
        selector: row => {
            if(!row.last_30_plant_performance) { return '0%'; }
            return `${row.last_30_plant_performance.toFixed(0)}%`;
        },
        right: true,
        width: '80px !important',
        sortField: 'last_30_plant_performance',
        style: {
            paddingLeft: "10px !important",
            paddingRight: "10px !important",
        }
    }
}