import Brand from "components/Authentication/Brand";
import React, {useState} from "react";
import EmailInput from "components/Forms/Inputs/EmailInput";
import PasswordInput from "components/Forms/Inputs/PasswordInput";
// import LanguagesFooter from "components/Langauge";
import PrivacyPolicyCheckBox from "components/Forms/Inputs/PrivacyPolicyCheckBox";
import {clearRemoteValidatorError, getValue} from "utils/Forms";
import NameInput from "components/Forms/Inputs/NameInput";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import DividerEdit from "components/Forms/DividerEdit";
import {SignUpRequest} from "utils/Requests/Auth";
import FooterLink from "components/Forms/Auth/FooterLink";
import CodeInput from '../../../components/Forms/Inputs/CodeInput';

export default function Signup({ ...props }) {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const formReference = React.useRef();

    const [formData, setFormData] = useState({
        user_name: null,
        user_last_name: null,
        user_email: null,
        user_password: null,
        user_accepted_tos: null,
        user_language: null,
        admin_code: null
    });

    const [errors, setErrors] = useState({})

    //handler on each input change
    const handleInputChange = (event) => {
        clearRemoteValidatorError(formReference);
        setErrors({})
        setFormData({ ...formData, [event.target.name] : getValue(event) })
    } // handler for submit form
    const handleSubmit = (e) => {
        SignUpRequest(e, formData, formReference, () => { navigate('/login'); }, (errors) => { setErrors(errors) });
    }

    return (
        <div className="card-body">
            <Brand />
            <h4 className="card-title mb-1 mt-2">{ t('signup.title') } </h4>
            <p className="card-text mb-2">{ t('signup.description') }</p>
            <DividerEdit />
            <form ref={formReference} className="auth-register-form mt-2" noValidate>
                <NameInput onChange={handleInputChange} tabIndex="1"/>
                <EmailInput onChange={handleInputChange} tabIndex="3"/>
                <PasswordInput onChange={handleInputChange} tabIndex="4"/>
                <CodeInput onChange={handleInputChange} tabIndex="5" errors={errors}/>
                <PrivacyPolicyCheckBox back={pathname} onChange={handleInputChange} tabIndex="6"/>
                <button className="btn btn-primary w-100 btn-submit" onClick={handleSubmit} tabIndex="7">{ t('signup.button') }</button>
            </form>
            <FooterLink text={ [t('signup.account_question'), t('login.sign_in')] } to="/login" />
            {/*<LanguagesFooter />*/}
        </div>
    )
}
