import React from "react";
import {Droplet, Minus, Plus, Sun, Thermometer, ThumbsUp, Zap, AlertTriangle} from "react-feather";
import { TbDatabaseExclamation } from "react-icons/tb";
import {MdHistoryToggleOff} from 'react-icons/md';
import { AiOutlineAlert } from "react-icons/ai";
import { Badge } from 'reactstrap';
import {Badge as AntdBadge} from "antd";
import {Tooltip} from "antd";
import * as cleanTextUtils from 'clean-text-utils';
import i18next from "i18next";
import {
    getActionsColumn,
    getColumnTableProduction,
    getGlobalStatusColumn,
    getLocationColumn, getMaxColumn, getMinColumn,
    getNameColumn, getPerformanceColumn, getProductionColumn/*, getShowColumn*/
} from "components/Table/Columns";
import { AiOutlineSignature } from "react-icons/ai";
import _ from "lodash";

const textCleaner = require('text-cleaner');

export const _SOLAR_THERMAL = 'solarThermal';
export const _SOLAR_PHOTOVOLTAIC = 'photovoltaic';
export const _THERMAL = 'thermal';
export const _WATER = 'water';
export const _GRAY_WATER = 'gray_water';

export const _GLOBAL_STATUS = 'global_status';
export const _STATUS = 'status';
export const _ALERTS = 'alerts';

export const _DASHBOARD = 'dashboard';

export const solarThermalColor = '#fac108';
export const solarPhotovoltaicColor = '#f89f43';
export const thermalColor = '#b9de47';
export const waterColor = '#54c8f1';

export const solarThermalProgressBarClass = 'progress-bar-solar-thermal';
export const solarPhotovoltaicProgressBarClass = 'progress-bar-warning';

export const solarThermalBgClass = 'bg-light-thermal';
export const solarPhotovoltaicBgClass = 'bg-light-photovoltaic';
export const waterBgClass = 'bg-light-water';

export function isObject(el) {
    return (typeof el === 'object' && !Array.isArray(el) && el !== null);
}

export function isSolarThermalByCaller(props) {
    return (props.caller === _SOLAR_THERMAL);
}

export function isSolarPhotovoltaicByCaller(props) {
    return (props.caller === _SOLAR_PHOTOVOLTAIC);
}

export function isThermalByCaller(props) {
    return (props.caller === _THERMAL);
}

export function isWaterByCaller(props) {
    return (props.caller === _WATER);
}

export function isStatusByType(props) {
    return (props.type === _STATUS);
}

export function isAlertsByType(props) {
    return (props.type === _ALERTS);
}

export function isDashboardType(props) {
    return (props.type === _DASHBOARD);
}

export function getProgressBarClassByCaller(props) {
    if (isSolarThermalByCaller(props)) {
        return solarThermalProgressBarClass;
    }
    return 'progress-bar-warning';
}
export function getSecondReading(props){
        return props.caller.second_reading;
}
export function getColorProductionByCaller(props) {
    if (isSolarThermalByCaller(props)) {
        return solarThermalColor;
    } else if(isThermalByCaller(props)) {
        return thermalColor;
    } else if(isWaterByCaller(props)) {
        return waterColor;
    } //default
    return solarPhotovoltaicColor;
}

export function getBgClassByCaller(props) {
    if (isSolarThermalByCaller(props)) {
        return solarThermalBgClass;
    } else if(isWaterByCaller(props)) {
        return waterBgClass;
    }
    return solarPhotovoltaicBgClass;
}

export function getIconComponentByCaller(config, props) {
    if (isSolarThermalByCaller(config)) {
        return <Sun {...props} />;
    } else if(isWaterByCaller(config)) {
        return <Droplet {...props} />;
    } else if(isThermalByCaller(config)) {
        return <Thermometer {...props} />;
    }
    return <Zap {...props} />;
}

export function getUnderproductionTextByCaller(props) {
    if(isThermalByCaller(props)) {
        return 'events.low_temp';
    } else if(isWaterByCaller(props)) {
        return 'events.underconsumption_abbr';
    }
    return 'events.underproduction';
}

export function getOverproductionTextByCaller(props) {
    if(isThermalByCaller(props)) {
        return 'events.high_temp';
    } else if(isWaterByCaller(props)) {
        return 'events.overconsumption_abbr';
    }
    return 'events.overproduction';
}

/*
+-------------------+----------------------------+-----------------------------+
| plant_incident_id | plant_incident_description | plant_incident_abbreviation |
+-------------------+----------------------------+-----------------------------+
|                 1 | OK                         | OK                          |
|                 2 | No data                    | N                           |
|                 3 | Wrong value                | N                           |
|                 4 | 404                        | N                           |
|                 5 | Overproduction             | E+                          |
|                 6 | Underproduction            | E-                          |
|                 7 | Waiting                    | W                           |
|                 8 | Off                        | Off                         |
+-------------------+----------------------------+-----------------------------+
 */

export function getIconByPlantStatus(config, alert, text = undefined) {
    alert = parseInt(alert);
    console.log("alerta de iconbyPlantStatus",alert);
    if(alert === 2 || alert === 3 || alert === 4 || alert === 8 || alert === 0 || alert === 7) {
        return (
            <Tooltip placement="topLeft" title={i18next.t('events.no_reading')} overlayStyle={{fontSize: '13px'}}>
                <Badge color='light-danger' className={"badge-icon-lg"}>
                    {text && <span className={"d-flex align-items-center"}><TbDatabaseExclamation /><span style={{marginLeft: "5px"}}>{text}</span></span>}
                    {!text && <TbDatabaseExclamation />}
                </Badge>
            </Tooltip>
        );
    } else if(alert === 5) {
        let tooltip = i18next.t('events.overproduction');
        if(isWaterByCaller(config)) {
            tooltip = i18next.t('events.overconsumption');
        } else if(isThermalByCaller(config)) {
            tooltip = i18next.t('events.high_temp');
        }

        return (
            <Tooltip placement="topLeft" title={tooltip} overlayStyle={{fontSize: '13px'}}>
                <Badge color='light-info' className={"badge-icon-lg"}>
                    {text && <span className={"d-flex align-items-center"}><Plus /><span style={{marginLeft: "5px"}}>{text}</span></span>}
                    {!text && <Plus />}
                </Badge>
            </Tooltip>
        );
    } else if(alert === 6) {
        let tooltip = i18next.t('events.underproduction');
        if(isWaterByCaller(config)) {
            tooltip = i18next.t('events.underconsumption');
        } else if(isThermalByCaller(config)) {
            tooltip = i18next.t('events.low_temp');
        }
        return (
            <Tooltip placement="topLeft" title={tooltip} overlayStyle={{fontSize: '13px'}}>
                <Badge color='light-primary' className={"badge-icon-lg"}>
                    {text && <span className={"d-flex align-items-center"}><Minus /><span style={{marginLeft: "5px"}}>{text}</span></span>}
                    {!text && <Minus />}
                </Badge>
            </Tooltip>
        );
    } else if(alert === 7) {
        return (
            <Tooltip placement="topLeft" title={i18next.t('events.in_process')} overlayStyle={{fontSize: '13px'}}>
                <Badge color='light-warning' className={"badge-icon-lg badge-icon-no-stroke"}>
                    {text && <span className={"d-flex align-items-center"}><MdHistoryToggleOff /><span style={{marginLeft: "5px"}}>{text}</span></span>}
                    {!text && <MdHistoryToggleOff />}
                </Badge>
            </Tooltip>
        );
    // } else if(alert === 8) {
    //     return (
    //         <Tooltip placement="topLeft" title={i18next.t('events.off')} overlayStyle={{fontSize: '13px'}}>
    //             <Badge color='light-secondary' className={"badge-icon-lg badge-icon-no-stroke"}>
    //                 {text && <span className={"d-flex align-items-center"}><MdOutlinePowerOff /><span style={{marginLeft: "5px"}}>{text}</span></span>}
    //                 {!text && <MdOutlinePowerOff />}
    //             </Badge>
    //         </Tooltip>
    //     );
    // } else if(alert === 0) {
    //     return (
    //         <Tooltip placement="topLeft" title={i18next.t('events.no_sync')} overlayStyle={{fontSize: '13px'}}>
    //             <Badge color='light-secondary' className={"badge-icon-lg badge-icon-no-stroke"}>
    //                 {text && <span className={"d-flex align-items-center"}><MdLinkOff /><span style={{marginLeft: "5px"}}>{text}</span></span>}
    //                 {!text && <MdLinkOff />}
    //             </Badge>
    //         </Tooltip>
    //     );
    } else if(!config.hideEventOk) {
        return (
            <Tooltip placement="topLeft" title={i18next.t('events.ok')} overlayStyle={{fontSize: '13px'}}>
                <Badge color='light-success' className={"badge-icon-lg"}>
                    {text && <span className={"d-flex align-items-center"}><ThumbsUp /><span style={{marginLeft: "5px"}}>{text}</span></span>}
                    {!text && <ThumbsUp />}
                </Badge>
            </Tooltip>
        );
    } else {
        return '-'
    }
}
export function getIconByPlantSecond_readingStatus(config, alert, text = undefined) {
    console.log(alert);
    alert = parseInt(alert);  // Solo convertimos a entero, sin redeclarar
    //console.log(alert);
    if (alert == 2 ){

        return (
            <Tooltip placement="topLeft" title={i18next.t('events.ok')} overlayStyle={{ fontSize: '13px' }}>
                <Badge 
                    className="badge-icon-lg" 
                    style={{ backgroundColor: '#ffffff', color: '#333333', padding: '5px 10px', fontWeight: 'bold' }} 
                >
                    {text && (
                        <span className="d-flex align-items-center">
                            <span style={{ color: '#333333', fontWeight: 'bold' }}>U</span>
                            <span style={{ marginLeft: "5px" }}>{text}</span>
                        </span>
                    )}
                    {!text && (
                        <span style={{ color: '#333333', fontWeight: 'bold' }}>U</span>
                    )}
                </Badge>    
            </Tooltip>
        );

    }
    else  if (alert === 1) {
        return (
            <Tooltip placement="topLeft" title={i18next.t('events.ok')} overlayStyle={{ fontSize: '13px' }}>
                <Badge color="light-warning" className="badge-icon-lg">
                    {text && (
                        <span className="d-flex align-items-center">
                            <span style={{ color: 'red', fontWeight: 'bold' }}>S</span>
                            <span style={{ marginLeft: "5px" }}>{text}</span>
                        </span>
                          )}
                    {!text && (
                        <span style={{ color: 'red', fontWeight: 'bold' }}>S</span>
                    )}
                </Badge>    
            </Tooltip>
        );
    } else if (alert === 0) {
        return (
            <Tooltip placement="topLeft" title={i18next.t('events.ok')} overlayStyle={{ fontSize: '13px' }}>
                <Badge color="light-success" className="badge-icon-lg">
                    {text && (
                        <span className="d-flex align-items-center">
                            <span style={{ color: 'green', fontWeight: 'bold' }}>S</span>
                            <span style={{ marginLeft: "5px" }}>{text}</span>
                        </span>
                          )}
                    {!text && (
                        <span style={{ color: 'green', fontWeight: 'bold' }}>S</span>
                    )}
                </Badge>    
            </Tooltip>
        );

    } else {
       //return '-';
       
           //return (
            ///<Tooltip placement="topLeft" title={i18next.t('events.ok')} overlayStyle={{ fontSize: '13px' }}>

                //<Badge color='light-success' className={"badge-icon-lg"}>
                  //  {text && <span className={"d-flex align-items-center"}><ThumbsUp /><span style={{ marginLeft: "5px" }}>{text}</span></span>}
                  //  {!text && <ThumbsUp />}
               // </Badge>
            //</Tooltip>

        //);
         return (
            <Tooltip placement="topLeft" title={i18next.t('events.no_reading')} overlayStyle={{ fontSize: '13px' }}>
                <Badge color='light-danger' className={"badge-icon-lg"}>
                    {text && <span className={"d-flex align-items-center"}><TbDatabaseExclamation /><span style={{ marginLeft: "5px" }}>{text}</span></span>}
                    {!text && <TbDatabaseExclamation />}
                </Badge>
            </Tooltip>
        );
    }
}
export function IconSecondR(){
    return "-";
}

export function getIconByPlantSecond_reading(config, alert, text = undefined) {

    alert = parseInt(alert);  // Solo convertimos a entero, sin redeclarar

    if (alert == 2 ){

        return (
            <Tooltip placement="topLeft" title={i18next.t('events.ok')} overlayStyle={{ fontSize: '13px' }}>
                <Badge 
                    className="badge-icon-lg" 
                    style={{ backgroundColor: '#ffffff', color: '#333333', padding: '5px 10px', fontWeight: 'bold' }} 
                >
                    {text && (
                        <span className="d-flex align-items-center">
                            <span style={{ color: '#333333', fontWeight: 'bold' }}>U</span>
                            <span style={{ marginLeft: "5px" }}>{text}</span>
                        </span>
                    )}
                    {!text && (
                        <span style={{ color: '#333333', fontWeight: 'bold' }}>U</span>
                    )}
                </Badge>    
            </Tooltip>
        );
        
        
    }
    else  if (alert === 1) {
        return (
            <Tooltip placement="topLeft" title={i18next.t('events.ok')} overlayStyle={{ fontSize: '13px' }}>
                <Badge color="light-warning" className="badge-icon-lg">
                    {text && (
                        <span className="d-flex align-items-center">
                            <span style={{ color: 'red', fontWeight: 'bold' }}>S</span>
                            <span style={{ marginLeft: "5px" }}>{text}</span>
                        </span>
                          )}
                    {!text && (
                        <span style={{ color: 'red', fontWeight: 'bold' }}>S</span>
                    )}
                </Badge>    
            </Tooltip>
        );}
        else if (alert === 0) {
            return '-';
            /*return (
            Tooltip placement="topLeft" title={i18next.t('events.ok')} overlayStyle={{ fontSize: '13px' }}>
                <Badge color='light-success' className={"badge-icon-lg"}>
                    {text && <span className={"d-flex align-items-center"}><ThumbsUp /><span style={{ marginLeft: "5px" }}>{text}</span></span>}
                    {!text && <ThumbsUp />}
                </Badge>
            </Tooltip>
        );*/
    } else if (!config.hideEventOk) {
        return (
            <Tooltip placement="topLeft" title={i18next.t('events.ok')} overlayStyle={{ fontSize: '13px' }}>
                <Badge color='light-success' className={"badge-icon-lg"}>
                    {text && <span className={"d-flex align-items-center"}><ThumbsUp /><span style={{ marginLeft: "5px" }}>{text}</span></span>}
                    {!text && <ThumbsUp />}
                </Badge>
            </Tooltip>
        );
    } else {
       //return '-';
       
           //return (
            ///<Tooltip placement="topLeft" title={i18next.t('events.ok')} overlayStyle={{ fontSize: '13px' }}>

                //<Badge color='light-success' className={"badge-icon-lg"}>
                  //  {text && <span className={"d-flex align-items-center"}><ThumbsUp /><span style={{ marginLeft: "5px" }}>{text}</span></span>}
                  //  {!text && <ThumbsUp />}
               // </Badge>
            //</Tooltip>

        //);
         return (
            <Tooltip placement="topLeft" title={i18next.t('events.no_reading')} overlayStyle={{ fontSize: '13px' }}>
                <Badge color='light-danger' className={"badge-icon-lg"}>
                    {text && <span className={"d-flex align-items-center"}><TbDatabaseExclamation /><span style={{ marginLeft: "5px" }}>{text}</span></span>}
                    {!text && <TbDatabaseExclamation />}
                </Badge>
            </Tooltip>
        );
    }
}

/*
+-------------------+----------------------------+-----------------------------+
| plant_incident_id | plant_incident_description | plant_incident_abbreviation |
+-------------------+----------------------------+-----------------------------+
|                 1 | OK                         | OK                          |
|                 2 | No data                    | N                           |
|                 3 | Wrong value                | N                           |
|                 4 | 404                        | N                           |
|                 5 | Overproduction             | E+                          |
|                 6 | Underproduction            | E-                          |
|                 7 | Waiting                    | W                           |
|                 8 | Off                        | Off                         |
+-------------------+----------------------------+-----------------------------+
 */
export function getIconByGlobalPlantStatus(config, row, text = undefined) {
    // default alert is warning
    let alert = undefined;
    if(isWaterByCaller(config) || isThermalByCaller(config)) {
        alert = parseInt(row.plant_hourly_alert_id)
    } else { // PV AND ST
        alert = parseInt(row.plant_daily_alert_id)
    }

    <AiOutlineSignature />
    // overproduction [5]
    // underproduction [6]
    // waiting [7]
    // off [8]
    // no sync [0]
    const warningIds = [5,6,7,0,8];
    // no reading, wrong value and 404 [2,3,4]
    // const failIds = [2,3,4]
    // ok ids
    const okIds = [1]
    const wrapperIconStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        width: '30px',
        height: '30px',
        borderRadius: '0.358rem',
        paddingLeft: '1px'
    }
    // if set plant status and is offer pending approval
    if(_.get(row, 'plant_manual_status', -1) === 1) {
        let status;
        if (warningIds.includes(alert)) {
            status = 'warning';
        } else if (okIds.includes(alert)) {
            status = 'success';
        } else {
            status = 'error';
        }
        const Dot = <sup data-show="true" className={`ant-scroll-number ant-badge-dot ant-badge-status-${status}`} style={{top: '6px', right: '6px'}}></sup>
        return (<Tooltip placement="topLeft" title={i18next.t('events.offer_pending_approval')} overlayStyle={{fontSize: '13px'}}>
            <AntdBadge className={'bg-approval-offer'} count={Dot}>
                <div className={'bg-approval-offer'} style={wrapperIconStyle}>
                    <AiOutlineSignature color={'rgb(129 129 129)'} size={19} />
                </div>
            </AntdBadge>
        </Tooltip>);
    } else if (warningIds.includes(alert)) {
        return (<Tooltip placement="topLeft" title={i18next.t('events.warning')} overlayStyle={{fontSize: '13px'}}>
                <AntdBadge className={'bg-light-warning'}>
                    <div className={'bg-light-warning'} style={wrapperIconStyle}>
                        <AlertTriangle size={19} />
                    </div>
                </AntdBadge>
            </Tooltip>
        );
    } else if(okIds.includes(alert)) {
        return (
            <Tooltip placement="topLeft" title={i18next.t('events.ok')} overlayStyle={{fontSize: '13px'}}>
                <AntdBadge className={'bg-light-success'}>
                    <div className={'bg-light-success'} style={wrapperIconStyle}>
                        <ThumbsUp size={19} />
                    </div>
                </AntdBadge>
            </Tooltip>
        );
    } else {
        return (
            <Tooltip placement="topLeft" title={i18next.t('events.fail')} overlayStyle={{fontSize: '13px'}}>
                <AntdBadge className={'bg-light-danger'}>
                    <div className={'bg-light-danger'} style={wrapperIconStyle}>
                        <AiOutlineAlert size={19} />
                    </div>
                </AntdBadge>
            </Tooltip>
        );
    }
}

export function getColorByEvent(eventId) {
    eventId = parseInt(eventId);
    if(eventId === 1) {
        return '#28c76f';
    } else if(eventId === 2) {
        return '#ea5455';
    } else if(eventId === 5) {
        return '#00cfe8';
    } else if(eventId === 6) {
        return '#7367f0';
    } else if(eventId === 7) {
        return '#ff9f43';
    } else if(eventId === 8) {
        return '#82868b';
    }
}


export function getColorByStatus(eventId) {
    if(eventId === '1') {
        return '#28c76f';
    } else if(eventId === '2,3,4') {
        return '#ea5455';
    } else if(eventId === '5,6,7,0,8') {
        return '#ff9f43';
    }
}

export function getTextUrlClean(text) {
    try {
        if(typeof text === 'string' || text instanceof String) {
            text = cleanTextUtils.replace.diacritics(text);
            text = cleanTextUtils.replace.smartChars(text);
            text = text.toLowerCase();
            return textCleaner(text).removeChars({ exclude: 'ñ1234567890'}).condense().trim().remove(' ').valueOf().replaceAll(' ', '').replaceAll('ñ', 'n');
        }
        return 'plant'
    } catch (error) {
        return 'plant'
    }
}

export function getAuxEnergyTranslationKey(id) {
    if(id === 1) {
        return 'aux_energy.electricity';
    } else if(id === 2) {
        return 'aux_energy.natural_gas';
    } else if(id === 3) {
        return 'aux_energy.diesel';
    }
}

export function getAuxEnergyTechTranslationKey(id) {
    if(id === 1) {
        return 'aux_energy.resistance';
    } else if(id === 2) {
        return 'aux_energy.heat_pump';
    } else if(id === 3) {
        return 'aux_energy.conventional_boiler';
    } else if(id === 4) {
        return 'aux_energy.low_temperature_boiler';
    } else if(id === 5) {
        return 'aux_energy.condensing_boiler';
    }
}

export function getProductionColumnValueByCaller(config, value) {
    if(isSolarThermalByCaller(config)) {
        return value;
    } else if(isSolarPhotovoltaicByCaller(config)) {
        return value / 1000;
    }
}


export function getProductionColumnsTableByTime(config,rows) {

    console.log("Contenido de rows",rows.map(row => row.second_reading));
    const secondrColumn = rows.some(row => row.second_reading===2)
    if(config.time === 'time.hourly') {

       if(secondrColumn){
        return [
            getColumnTableProduction(config, 'datefull'),
            // getColumnTableProduction(config, 'time'),
            getColumnTableProduction(config, 'production'),
            getColumnTableProduction(config, 'status'),
            getColumnTableProduction(config, 'second_reading')
            
        ];
       }
       else{
        return [
            getColumnTableProduction(config, 'datefull'),
            // getColumnTableProduction(config, 'time'),
            getColumnTableProduction(config, 'production'),
            getColumnTableProduction(config, 'status'),
            getColumnTableProduction(config, 'second_reading')
        ];
       }
        
    } else if(config.time === 'time.daily') {
        if(isWaterByCaller(config)) { //water plant
            return [
                getColumnTableProduction(config, 'datefull'),
                getColumnTableProduction(config, 'production'),
                getColumnTableProduction(config,'eco_savings'),
                getColumnTableProduction(config, 'status')
               
            ];
        } //other plants
        if(rows.map(row=>row.second_reading)===2){
            return [
                getColumnTableProduction(config, 'datefull'),
                getColumnTableProduction(config, 'production'),
                getColumnTableProduction(config,'eco_savings'),
                getColumnTableProduction(config,'co2_savings'),
                getColumnTableProduction(config, 'status'),
                
                
            ];
        }
        
        return [
            getColumnTableProduction(config, 'datefull'),
            getColumnTableProduction(config, 'production'),
            getColumnTableProduction(config,'eco_savings'),
            getColumnTableProduction(config,'co2_savings'),
            getColumnTableProduction(config, 'status'),
            getColumnTableProduction(config, 'second_reading')
            
        ];
    } else if(config.time === 'time.monthly') {
        if(isWaterByCaller(config)) { //water plant
            return [
                getColumnTableProduction(config, 'datefull'),
                getColumnTableProduction(config, 'production'),
                getColumnTableProduction(config,'eco_savings')
                
            ];
        } //other plants
        return [
            getColumnTableProduction(config, 'datefull'),
            getColumnTableProduction(config, 'production'),
            getColumnTableProduction(config,'eco_savings'),
            getColumnTableProduction(config,'co2_savings')
        ];
    }
}



export function getIndexTableByType(configuration, actions) {
    //START: SET COLUMNS
    //set default columns
    let columns = [];
    if (isSolarThermalByCaller(configuration)) {
        return [
            // getDailyStatusColumn(configuration),
            // getHourlyStatusColumn(configuration),
            // getShowColumn(configuration, actions),
            getNameColumn(configuration, actions),
            getLocationColumn(),
            // getProductionColumn(configuration),
            getPerformanceColumn(configuration),
            getGlobalStatusColumn(configuration),
            // getSinceColumn(),
            getActionsColumn(configuration, actions)
        ]
    } else if (isSolarPhotovoltaicByCaller(configuration)) {
        return [
            // getDailyStatusColumn(configuration),
            // getHourlyStatusColumn(configuration),
            // getShowColumn(configuration, actions),
            getNameColumn(configuration, actions),
            getLocationColumn(),
            // getProductionColumn(configuration),
            getPerformanceColumn(configuration),
            getGlobalStatusColumn(configuration),
            // getSinceColumn(),
            getActionsColumn(configuration, actions)
        ]
    } else if (isWaterByCaller(configuration)) {
        return [
            // getDailyStatusColumn(configuration),
            // getHourlyStatusColumn(configuration),
            // getShowColumn(configuration, actions),
            getNameColumn(configuration, actions),
            getLocationColumn(),
            getProductionColumn(configuration),
            // getEcoColumn(configuration),
            getMaxColumn(configuration),
            getGlobalStatusColumn(configuration),
            // getSinceColumn(),
            getActionsColumn(configuration, actions)
        ]
    } else if (isThermalByCaller(configuration)) {
        return [
            // getHourlyStatusColumn(configuration),
            // getShowColumn(configuration, actions),
            getNameColumn(configuration, actions),
            getLocationColumn(),
            getMinColumn(configuration),
            getProductionColumn(configuration),
            getMaxColumn(configuration),
            getGlobalStatusColumn(configuration),
            // getSinceColumn(),
            getActionsColumn(configuration, actions)
        ]
    }
    return columns;
}

