import React from "react";
import {isDashboardType} from "../../utils/Types";

/*export function CardSimpleHeader({...props}) {
    return (
        <>
            <div className={`card-header align-items-center`} style={{...props.style}} ref={element => {
                    if (element && isDashboardType(props)) element.style.setProperty('padding-bottom', '0', 'important');
                     
                }}
               
            >
                <div className={"d-flex align-items-center"}>
                    {props.icon && props.icon} <h4 className="card-title">{props.title}</h4>
                </div>
                { props.rightComponent && props.rightComponent }

                <span className={`${props.subtitleClass}`} style={{width: '100%'}}>{ props.subtitle }</span>
            </div>
        </>
    )
}

export function CardSimpleHeader({...props}) {
    
    return (
        <>
            <div className={`card-header align-items-center`} style={{...props.style, padding: "10px 11px",}} ref={element => {
                    if (element && isDashboardType(props)) element.style.setProperty('padding-bottom', '0', 'important');
                }}
            >
                <div className={"d-flex align-items-center"}>
                    {props.leftComponent && <div className="me-2">{props.leftComponent}</div>}  
                    {props.icon && props.icon} 
                    <h4 className="card-title">{props.title}</h4>
                </div>
                {props.rightComponent && props.rightComponent}

                <span className={`${props.subtitleClass}`} style={{width: '100%'}}>{props.subtitle}</span>
            </div>
        </>
    )
}*/export function CardSimpleHeader({...props}) {
    return (
        <>
            <div className={`card-header align-items-center d-flex justify-content-between`} style={{...props.style, padding: "10px 11px",}} ref={element => {
                    if (element && isDashboardType(props)) element.style.setProperty('padding-bottom', '0', 'important');
                }}
            >
                <div className="d-flex align-items-center">
                    {props.leftComponent && <div className="me-2">{props.leftComponent}</div>}  
                    {props.icon && props.icon} 
                    <h4 className="card-title">{props.title}</h4>
                </div>
                
                {/* Asegúrate de que el rightComponent esté también alineado en línea */}
                <div className="d-flex align-items-center">
                    {props.rightComponent && props.rightComponent}
                </div>

                <span className={`${props.subtitleClass}`} style={{width: '100%'}}>{props.subtitle}</span>
            </div>
        </>
    )
}


