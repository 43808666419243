import React, {useState} from "react";
import 'assets/css/loader.css';
import {useTranslation} from "react-i18next";
import {Info, Feather, Sun, Thermometer, Droplet, Zap} from "react-feather"
import {SyncSummary} from "components/Summary/SyncSummary";
import Skeleton from "react-loading-skeleton";
import {faSmileBeam} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CardWelcome} from "components/Cards/CardWelcome";
import {_SOLAR_PHOTOVOLTAIC, _SOLAR_THERMAL, _THERMAL, _WATER, _STATUS, _ALERTS} from "utils/Types";
import {Link} from "react-router-dom";
import {CardProduction} from "components/Cards/CardProduction";
import {CardImage} from "components/Cards/CardImage";
import {CardEcoSavings} from "components/Cards/CardEcoSavings";
import {HiExternalLink} from "react-icons/hi";
import {CardPlotEvents} from "../../components/Cards/CardPlotEvents";
import {CardPlotCircleEvents} from "../../components/Cards/CardPlotCircleEvents";

import config from "/app/src/config.js";

const isMobileOnly = config.mbl_version;

const getDivider = (Icon) => {
    return (
        <div className="row match-height ps-2 pe-2" style={{overflow: "hidden", marginTop: "-28px"}}>
            <div className='divider' >
                <div className='divider-text'>
                    <Icon style={{width: "18px", height: "18px"}} />
                </div>
            </div>
        </div>
    )
}

export default function PageAnalytics({...props}) {
    const {t} = useTranslation();
    //attributes to load number of plants or sets...
    const [welcomeData, setWelcomeData] = useState({
        title: <Skeleton style={{width: "100%"}}/>,
        message: <Skeleton style={{width: "100%"}}/>,
        avatar: <Skeleton cricle style={{width: "50px"}}/>
    });

    const setPlantCallback = (totals = 'Error...') => {
        //custom data
        let data = {...welcomeData, title: totals.total, message: totals.total, avatar: totals.total};
        //for global sync data
        if(Number.isInteger(totals.total)) {
            if(totals.total > 0) { //some sync
                data = {...welcomeData, title: 'analytics.some_sync', message: 'analytics.fun', avatar: <FontAwesomeIcon icon={faSmileBeam} /> };
            } else { //any sync
                data = {...welcomeData, title: 'analytics.any_synced', message: 'analytics.sync_plant', avatar: <Info /> };
            }
        } //for thermal
        /*if(Number.isInteger(totals.thermal)) {
            setThermalTotal(totals.thermal);
        } else {
            setThermalTotal('Error...');
        }*/ //set data
        setWelcomeData({...welcomeData, ...data});
    }

    return (
     <>
        { !isMobileOnly && <div className="content-header row mt-2" /> }
         <div className="content-body">
             <section id="dashboard-analytics">
                { /* first row */ }
                <div className={`row match-height ${isMobileOnly ? "row-mbl match-height-mbl" : ""}`}>
                    { /* Welcome cart */ }
                    { !isMobileOnly &&
                         <div className={`col-lg-6 col-md-12 col-sm-12 col-12 ${isMobileOnly ? "col-mbl" : ""}`}>
                             <CardWelcome {...props} data={welcomeData} />
                         </div>
                     }

                     { /* sync plant summary */ }
                     <div className={`col-lg-3 col-md-6 col-sm-6 col-xs-12 col-12 ${isMobileOnly ? "col-mbl" : ""}`}>
                         <SyncSummary callback={setPlantCallback} type="1" title={t('plants')}  text={t('analytics.synchronized_plants')}
                                      noBadget={true}
                                      style={isMobileOnly ? {borderBottom: "1px solid rgb(195, 194, 194)"} : {}}
                         />
                     </div>

                    { !isMobileOnly &&
                         <div className={`col-lg-3 col-md-6 col-sm-6 hide-576 ${isMobileOnly ? "col-mbl" : ""}`}>
                            <CardImage src={"/img/foo/work01.png"} alt={"Work foo image"} style={{width: "100%"}}/>
                         </div>
                    }
                </div>

                 { !isMobileOnly ? <>
                     { /* Title */ }
                     { getDivider(Sun) }
                     { /* second row */ }
                     <div className="row match-height">
                         <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                             <SyncSummary number={<Skeleton style={{width: "50px"}} />} caller={_SOLAR_THERMAL} type="1" title={t('plants')} icon={ <Feather />}
                                          text={<>{ t('thermal') }<Link to="/dashboard/plants/thermal"><HiExternalLink /></Link></>}
                             />
                         </div>
                         <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 hide-992-1440">
                             <CardProduction caller={_SOLAR_THERMAL} />
                         </div>
                         <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 hide-992-1440">
                             <CardEcoSavings caller={_SOLAR_THERMAL} />
                         </div>
                         <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-6 col-sm-6 col-12 hide-450-575 hide-784-1440">
                             <CardPlotCircleEvents caller={_SOLAR_THERMAL} type={_STATUS} />
                         </div>
                         <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-6 col-sm-6 col-12 hide-450-575 hide-784-1440">
                             <CardPlotCircleEvents caller={_SOLAR_THERMAL} type={_ALERTS} />
                         </div>
                         <div className="col-xxl-6 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 show-450-575 show-784-1440">
                             <CardPlotEvents caller={_SOLAR_THERMAL} />
                         </div>
                     </div>

                     { /* Title */ }
                     { getDivider(Zap) }
                     { /* second row */ }
                     <div className="row match-height">
                         <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                             <SyncSummary number={<Skeleton style={{width: "50px"}} />} caller={_SOLAR_PHOTOVOLTAIC} type="1" title={t('plants')} icon={ <Feather />} text={
                                 <>{ t('photovoltaic') }<Link to="/dashboard/plants/photovoltaic"><HiExternalLink /></Link></>
                             }/>
                         </div>
                         <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 hide-992-1440">
                             <CardProduction caller={_SOLAR_PHOTOVOLTAIC} />
                         </div>
                         <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 hide-992-1440">
                             <CardEcoSavings caller={_SOLAR_PHOTOVOLTAIC} />
                         </div>
                         <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-6 col-sm-6 col-12 hide-450-575 hide-784-1440">
                             <CardPlotCircleEvents caller={_SOLAR_PHOTOVOLTAIC} type={_STATUS} />
                         </div>
                         <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-6 col-sm-6 col-12 hide-450-575 hide-784-1440">
                             <CardPlotCircleEvents caller={_SOLAR_PHOTOVOLTAIC} type={_ALERTS} />
                         </div>
                         <div className="col-xxl-6 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 show-450-575 show-784-1440">
                             <CardPlotEvents caller={_SOLAR_PHOTOVOLTAIC} />
                         </div>
                     </div>

                     { /* Title */ }
                     { getDivider(Thermometer) }
                     { /* second row */ }
                     <div className="row match-height">
                         <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                             <SyncSummary number={<Skeleton style={{width: "50px"}} />} caller={_THERMAL} type="1" title={t('plants')} icon={ <Feather />} text={
                                 <>{ t('temperature') }<Link to="/dashboard/plants/temperature"><HiExternalLink /></Link></>
                             }/>
                         </div>
                         <div className="col-xxl-4 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 hide-992 hide-992-1440">
                             <CardImage src={"/img/foo/work03.png"} alt={"Work foo image"} style={{width: "440px"}}/>
                         </div>
                         <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 show-450 hide-450-575 hide-576">
                             <CardPlotCircleEvents caller={_THERMAL} type={_ALERTS} />
                         </div>
                         <div className="col-xxl-6 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 show-450-575 show-576">
                             <CardPlotEvents caller={_THERMAL} type={_ALERTS} />
                         </div>
                     </div>

                     { /* Title */ }
                     { getDivider(Droplet) }
                     { /* second row */ }
                     <div className="row match-height">
                         <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                             <SyncSummary number={<Skeleton style={{width: "50px"}} />} caller={_WATER} type="1" title={t('plants')} icon={ <Feather />} text={
                                 <>{ t('water') }<Link to="/dashboard/plants/water"><HiExternalLink /></Link></>
                             }/>
                         </div>
                         <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 hide-992-1440">
                             <CardProduction caller={_WATER} />
                         </div>
                         <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 hide-992-1440">
                             <CardEcoSavings caller={_WATER} />
                         </div>
                         { /* first row left group: events */ }
                         <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 show-450 hide-450-575 hide-576">
                             <CardPlotCircleEvents caller={_WATER} type={_ALERTS} />
                         </div>
                         <div className="col-xxl-6 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 show-450-575 show-576">
                             <CardPlotEvents caller={_WATER} type={_ALERTS} />
                         </div>
                     </div>
                 </> : <>
                     { /* _SOLAR_THERMAL */}
                     <Link to="/dashboard/plants/thermal">
                         <SyncSummary number={<Skeleton style={{width: "50px"}} />} caller={_SOLAR_THERMAL} type="1" title={t('plants')} icon={ <Sun />}
                                      text={ t('thermal') } style={{backgroundColor: "#fbfbfb"}}
                         />
                      </Link>
                     { /* _SOLAR_PHOTOVOLTAIC */}
                     <Link to="/dashboard/plants/photovoltaic">
                         <SyncSummary number={<Skeleton style={{width: "50px"}} />} caller={_SOLAR_PHOTOVOLTAIC} type="1" title={t('plants')} icon={ <Zap />}
                                      text={t('photovoltaic')} style={{backgroundColor: "#fbfbfb"}}
                         />
                     </Link>
                     { /* _THERMAL */}
                     <Link to="/dashboard/plants/temperature">
                         <SyncSummary number={<Skeleton style={{width: "50px"}} />} caller={_THERMAL} type="1" title={t('plants')} icon={ <Thermometer />}
                                      text={t('temperature') } style={{backgroundColor: "#fbfbfb"}}
                         />
                     </Link>
                     { /* _WATER */}
                     <Link to="/dashboard/plants/water">
                         <SyncSummary number={<Skeleton style={{width: "50px"}} />} caller={_WATER} type="1" title={t('plants')} icon={ <Droplet />}
                                      text={t('water')} style={{backgroundColor: "#fbfbfb"}}
                         />
                     </Link>
                 </>}
             </section>
         </div>
     </>)
}