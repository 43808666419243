import React, {useEffect, useRef, useState} from "react";
import DataTable from "react-data-table-component";
import {ChevronDown, Trash, Eye, RefreshCw} from "react-feather";
import {Card, CardBody, CardHeader, Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import 'assets/css/react-dataTable.scss';
import {useTranslation} from "react-i18next";
import {
    getIconComponentByCaller,
    getIndexTableByType,
    getTextUrlClean, isSolarPhotovoltaicByCaller,
    isSolarThermalByCaller, isThermalByCaller, isWaterByCaller
} from "utils/Types";
import {Input} from 'antd';
import {Search} from 'react-feather';
import {deleteUserPlant, getUserPlants} from "utils/Requests/Plant";
import LoadingBar from "react-top-loading-bar";
import {removeBlurButton} from 'utils/Buttons';
import {getValue} from "utils/Forms";
import {useLocation, useNavigate} from "react-router-dom";
import {SearchAlert} from "components/Alerts";
import {DataTableError, DataTableNoRecords, DataTableLoader} from "components/Table/PlantsTableCommon";
import {CardOverlayLoader} from "../Cards/CardOverlayLoader";
import i18next from "i18next";
import {globalStatusOptions, StatusInput} from "components/Forms/Inputs/EventsInput";
import config from "/app/src/config.js";
import { LuFilterX } from "react-icons/lu";
import {getStatusSelected} from "../Badge/BadgeGlobalStatusSummary";

const _ = require('lodash');
const isMobileOnly = config.mbl_version;

/* default pagination per page */
const defaultPaginationPerPage = 20;
const defaultPaginationNumber = [10, 15, 20, 25, 30];

const DesktopForm = (props) => {
    const {t} = useTranslation();
    const {statusNameField, formData, handleInputChange, handlerSearch, handleSelectChange} = props;

    return (<>
        { /* old daily alert and hourly alert */ }
        {/*{ isExtendedForm &&*/}
        {/*    <Col xxl="3" xl="6" lg='6' md='6'>*/}
        {/*        <div className={"mb-1"}>*/}
        {/*            <EventsSelect value={formData.plant_daily_alert_id??[]} name='plant_daily_alert_id' onChange={handleSelectChange} label={t('plant.alerts.daily')}/>*/}
        {/*        </div>*/}
        {/*    </Col>*/}
        {/*}*/}
        {/*<Col xxl="3" xl={!isExtendedForm?'4':'6'} lg='6' md='6'>*/}
        {/*    <div className={"mb-1"}>*/}
        {/*        <EventsSelect value={formData.plant_hourly_alert_id??[]} name='plant_hourly_alert_id' onChange={handleSelectChange} label={t('plant.alerts.hourly')}/>*/}
        {/*    </div>*/}
        {/*</Col>*/}
        { /* new combined search location and name in same field */ }
        <Col xxl="8" xl='8' lg='12' md='12' style={{paddingLeft: 0}}>
            <div className={"mb-1"}>
                <Input placeholder={t("searches.placeholders.location_or_name")} value={formData.search_plant_name_or_address} name='search_plant_name_or_address' onChange={handleInputChange} onPressEnter={handlerSearch}/>
            </div>
        </Col>
        { /* old plant name and location with buttons */ }
        {/*<Col xxl={!isExtendedForm?'6':'3'} xl={!isExtendedForm?'4':'6'} lg="6" md='6'>*/}
        {/*    <div className={"mb-1"}>*/}
        {/*        <Label for='name'>{ t('name') }</Label>*/}
        {/*        <Input value={formData.search_name} name='search_name' onChange={handleInputChange} onPressEnter={handlerSearch} />*/}
        {/*    </div>*/}
        {/*</Col>*/}
        {/*<Col xxl="3" xl={!isExtendedForm?'4':'6'} lg={!isExtendedForm?'12':'6'} md={!isExtendedForm?'12':'6'}>*/}
        {/*    <Label for='name'>{ t('location') }</Label>*/}
        {/*    <div className={"mb-1 d-flex justify-content-between"}>*/}
        {/*        <Input value={formData.search_address} name='search_address' onChange={handleInputChange} onPressEnter={handlerSearch} />*/}
        {/*        <div className='ms-1'>*/}
        {/*            <Button outline onClick={handlerSearch} color='primary' size='sm' style={{padding: "0.5rem 0.5rem"}}>*/}
        {/*                <Search size={15} />*/}
        {/*            </Button>*/}
        {/*        </div>*/}
        {/*        <div style={{marginLeft: "5px"}}>*/}
        {/*            <Button outline onClick={handlerSearch} color='secondary' size='sm' style={{padding: "0.5rem 0.5rem"}}>*/}
        {/*                <RefreshCw size={15} />*/}
        {/*            </Button>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</Col>*/}
        { /* new search by status */ }
        <Col xxl="4" xl='4' lg='12' md='12' style={{paddingLeft: 0}}>
            {/*<Label for='name'>{ t('location') }</Label>*/}
            <div className={"mb-1 d-flex justify-content-between"}>
                { /* TODO MANUAL STATUS INPUT COMBOBOX */ }
                <StatusInput loading={formData.isLoading} options={_.reject(globalStatusOptions, { id: 'offer_pending_approval' })} disabled={formData.isDisabled} value={formData[statusNameField] ?? []} name={statusNameField} onChange={handleSelectChange} label={t("searches.placeholders.status")}/>
                <div className='ms-1'>
                    <Button outline onClick={handlerSearch} color='primary' size='sm' style={{padding: "0.5rem 0.5rem"}}>
                        <Search size={15}/>
                    </Button>
                </div>
                <div style={{marginLeft: "5px"}}>
                    <Button outline onClick={handlerSearch} color='secondary' size='sm' style={{padding: "0.5rem 0.5rem"}}>
                        <RefreshCw size={15}/>
                    </Button>
                </div>
            </div>
        </Col>
    </>)
}

const MobileForm = (props) => {
    const {t} = useTranslation();
    const {formData, handleInputChange, handlerSearch, handlerSearchRemove} = props;

    return (<>
        { /* new search by status */ }
        <Col xxl="4" xl='4' lg='12' md='12' style={{paddingLeft: 0}}>
            {/*<Label for='name'>{ t('location') }</Label>*/}
            <div className={"mb-1 d-flex justify-content-between"}>
                <Input placeholder={t("searches.placeholders.location_or_name")} value={formData.search_plant_name_or_address} name='search_plant_name_or_address' onChange={handleInputChange} onPressEnter={handlerSearch}/>
                <div className='ms-1'>
                    <Button outline onClick={handlerSearch} color='primary' size='sm' style={{padding: "0.5rem 0.5rem"}}>
                        <Search size={15}/>
                    </Button>
                </div>
                <div style={{marginLeft: "5px"}}>
                    <Button outline onClick={handlerSearchRemove} color='secondary' size='sm' style={{padding: "0.5rem 0.5rem"}}>
                        <LuFilterX size={16}/>
                    </Button>
                </div>
            </div>
        </Col>
    </>)
}

/* grid form */
const SearchPlantForm = ({...props}) => {
    const {configuration, searches} = props;
    const {fields} = configuration;
    const {status: statusNameField} = fields;
    const [formData, setFormData] = useState({
        isLoading: true,
        isDisabled: true
    });

    useEffect(() => { // when change by badge
        // set first filter
        setFormData({...formData, isDisabled: false, isLoading: false, ...searches })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searches])

    //on each input change...
    const handleInputChange = (event) => {
        const obj = { ...formData, [event.target.name] : getValue(event) };
        if(!getValue(event)) {
            delete obj[event.target.name];
        }
        //set object data
        setFormData(obj);
    }

    //on each select change...
    const handleSelectChange = (name, value) => {
        // TODO TEMPORAL FIX plant_manual_status: -1
        const obj = { ...formData, [name] : value, plant_manual_status: -1 };
        if(value.length < 1) {
            delete obj[name];
            // TODO TEMPORAL FIX plant_manual_status: -1
            delete obj['plant_manual_status'];
        } //set object data
        setFormData(obj);
    }

    //call to search
    const handlerSearch = () => {
        props.handlerSearch(_.omit(formData, ['isLoading', 'isDisabled']));
    }

    //call to cancel
    const handlerSearchRemove = () => {
        setFormData({});
        props.handlerSearchRemove();
    }

    return (<>
        <Row className='mt-1 mb-0 grid-form' disabled style={{paddingLeft: '15px'}}>
            { !isMobileOnly
                ? <DesktopForm statusNameField={statusNameField} formData={formData} setFormData={setFormData} handleInputChange={handleInputChange} handlerSearch={handlerSearch} handleSelectChange={handleSelectChange} />
                : <MobileForm statusNameField={statusNameField} formData={formData} setFormData={setFormData} handleInputChange={handleInputChange} handlerSearch={handlerSearch} handleSelectChange={handleSelectChange} handlerSearchRemove={handlerSearchRemove} />
            }
        </Row>
        {(!isMobileOnly && !_.isEmpty(props.searches)) && <SearchAlert handler={handlerSearchRemove}/>}
    </>)
}

/* grid menu */
export function TableIconsRowMenu({...props}) {
    return (<>
        <span className={"table-row-menu"}>
            <Eye onClick={() => props.showHandler(props.row) } className={"icon-me"}/>
            <Trash onClick={() =>  props.trashHandler(props.row) } />
       </span>
    </>);
}

/* grid */
export function PlantsTable({...props}) {
    const {t} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const dataTableContainerReference = useRef();
    const loadingBarReference = useRef();
    // get selected status value
    const { value: statusSelected, field: statusNameField } = getStatusSelected(props, location)
    // initial searches
    const searches = statusSelected ? { [statusNameField]: statusSelected } : {}
    // set global configuration
    const [configuration, setConfiguration] =  useState({
        ...props,
       
        rows: [],
        isLoading: true,
        message: <DataTableNoRecords />,
        totalRows: 0,
        disabled: true,
        tableConfig: {
            sort: { column: 'plant_name', direction: 'ASC', },
            pagination: { perPage: defaultPaginationPerPage, page: 1},
            // set default search -> form dashboard
            search: searches
        },
        paginationOption: {
            rowsPerPageText: t('pagination.rows_per_page'),
            rangeSeparatorText: t('pagination.page_separator'),
            selectAllRowsItem: false,
            selectAllRowsItemText: t('pagination.all')
        },
        modal: {
            open: false,
            selected: {}
        },
        fields: {
            status: statusNameField
        }
    });

    //set translate use effect call
    const translateEffectDependency = t('plant.daily_status');
    //fix for pagination translation
    useEffect(() => {
        //translate
        setConfiguration(configuration => {
            if(configuration.isLoading) { return configuration; }
            return {...configuration, paginationOption: {
                    rowsPerPageText: i18next.t('pagination.rows_per_page'),
                    rangeSeparatorText: i18next.t('pagination.page_separator'),
                    selectAllRowsItem: true,
                    selectAllRowsItemText: i18next.t('pagination.all'),
                }
            }
        });
    }, [translateEffectDependency]);

    //effect to load data...
    useEffect(() => {
        // clean up controller --> prevent crash with async function
        let isMounted = true;
        //only on first load...
        if(!configuration.isLoading) { return; }
        //load data
        const requestRows = (config) => {
            //remove blur on buttons
            removeBlurButton(dataTableContainerReference.current).then(() => {});
            //only show in not first load
            if(!configuration.isLoading) { loadingBarReference.current.continuousStart(); }
            //get user plants
            getUserPlants({...configuration, ...configuration.tableConfig}, (response) => {
                //prevent crash with async function
                if (!isMounted) return null;
                //set configuration
                setConfiguration({...configuration, rows: response.data.plants, isLoading: false, message: <DataTableNoRecords />, totalRows: response.data.total, disabled: false });
                //end the progress bar...
                loadingBarReference.current.complete();
            }, () => {
                //prevent crash with async function
                if (!isMounted) return null;
                //set configuration
                setConfiguration({...configuration, rows: [], isLoading: false, message: <DataTableError />, disabled: false });
                //end the progress bar...
                loadingBarReference.current.complete();
            });
        }; //load data...
        requestRows(configuration);
        //prevent crash with async function
        return () => { isMounted = false; };
    }, [configuration]);

    // when change status selected (from header)...
    useEffect(() => {
        // not run if component is loading...
        if(configuration.isLoading) { return; }
        // remove old searches by status
        const searches = _.omit(configuration.tableConfig.search, ['plant_daily_alert_id', 'plant_hourly_alert_id', 'plant_manual_status']);
        // fix for plant_manual_status value
        if(['plant_daily_alert_id', 'plant_hourly_alert_id'].includes(statusNameField)) {
            // change searches
            handlerSearch({...searches, [statusNameField]: statusSelected, plant_manual_status: -1 });
        } else {
            // change searches
            handlerSearch({...searches, [statusNameField]: statusSelected});
        }
    }, [statusSelected, statusNameField])

    // when searches is clean by handlerSearchRemove...
    useEffect(() => {
        // reload without filters
        if(!configuration.tableConfig.search) navigate(".", { replace: false });
    }, [configuration.tableConfig.search]);

    //on show handler
    const handlerShow = (row) => {
        if(isSolarThermalByCaller(configuration)) {
           navigate(`/dashboard/plants/thermal/${row.id}-${getTextUrlClean(row.plant_name)}`)
        } else if(isSolarPhotovoltaicByCaller(configuration)) {
            navigate(`/dashboard/plants/photovoltaic/${row.id}-${getTextUrlClean(row.plant_name)}`)
        } else if(isThermalByCaller(configuration)) {
            navigate(`/dashboard/plants/temperature/${row.id}-${getTextUrlClean(row.plant_name)}`)
        } else if(isWaterByCaller(configuration)) {
            navigate(`/dashboard/plants/water/${row.id}-${getTextUrlClean(row.plant_name)}`)
        }
    }

    //on handle sort
    const handlerSort = (column, sortDirection) => {
        setConfiguration({...configuration, disabled: true, isLoading: true, tableConfig: { ...configuration.tableConfig, sort: { column: column.sortField, direction: sortDirection }}});
    }
    //on handle page
    const handlePageChange = (page) => {
        setConfiguration({...configuration, disabled: true, isLoading: true, tableConfig: { ...configuration.tableConfig, pagination: { perPage: configuration.tableConfig.pagination.perPage, page: page}}});
    };
    //on handle per page
    const handlePerRowsChange = (perPage, page) => {
        setConfiguration({...configuration, disabled: true, isLoading: true, tableConfig: { ...configuration.tableConfig, pagination: { perPage: perPage, page: 1}}});
    };
    //on handle search
    const handlerSearch = (formData) => {
        setConfiguration({...configuration, disabled: true, isLoading: true, tableConfig: { ...configuration.tableConfig, pagination: { perPage: configuration.tableConfig.pagination.perPage, page: 1}, search: formData }});
    }
    //on handle reset search
    const handlerSearchRemove = () => {
        setConfiguration({...configuration, disabled: true, isLoading: true, tableConfig: { ...configuration.tableConfig, pagination: { perPage: configuration.tableConfig.pagination.perPage, page: 1}, search: null }});
    }
    //on handler click row
    const handlerRowClick = (row, event) => {
        handlerShow(row)
    }

    //on trash handler
    const handlerTrash = () => {
        //disable table --> disabled event is in loader bar
        setConfiguration({...configuration, disabled: true});
        //only show in not first load
        if(!configuration.isLoading) { loadingBarReference.current.continuousStart(); }
        //call to delete plant
        deleteUserPlant(configuration.modal.selected.id, (response) => {
            //end the progress bar...
            loadingBarReference.current.complete();
            //update total data
            configuration.numberHandler();
            setConfiguration({
                ...configuration,
                isLoading: true,
                tableConfig: { ...configuration.tableConfig, pagination: { perPage: configuration.tableConfig.pagination.perPage, page: 1 } },
                modal: { open: false, selected: {} }
            });
        }, () => {
            setConfiguration({
                ...configuration, rows: [], isLoading: false, disabled: false, message: <DataTableError />,
                modal: { open: false, selected: {} }
            });
            //end the progress bar...
            loadingBarReference.current.complete();
        });
    }

    const handlerCloseDeleteModal = () => {
        setConfiguration({...configuration, modal: { ...configuration.modal, open: false, selected: {} }});
    }

    const handlerShowDeleteModal = (row) => {
        setConfiguration({...configuration, modal: { ...configuration.modal, open: true, selected: row }});
    }

    //get columns for index
    const columns = getIndexTableByType(configuration,{ showHandler: handlerShow, trashHandler: handlerShowDeleteModal });
    //set a title for table
    const icon = getIconComponentByCaller(props, {size: 20, style: {marginRight: "5px"}})

    /* render component */
    return (
        <span ref={dataTableContainerReference}>
            <Card className={isMobileOnly?"card-mbl":""}>
                { /* not show header on mbl */
                    !isMobileOnly && <CardHeader className={"border-bottom"}>
                        <div className={"d-flex align-items-center"}>
                            {icon}
                            <span className="card-title">
                                {t('plants')}
                            </span>
                        </div>
                    </CardHeader>
                }
                <CardBody className={`mb-0 pb-0 ${isMobileOnly && "pt-0"}`} style={isMobileOnly ? {borderBottom: "1px solid #d9d9d9"}:{}}>
                    <SearchPlantForm configuration={configuration} searches={configuration.tableConfig.search} handlerSearch={handlerSearch} handlerSearchRemove={handlerSearchRemove} disabled={configuration.disabled} />
                </CardBody>
                { /* table with data */}
                <div className={!isMobileOnly?"react-dataTable":"non-class"}>
                    
                    <LoadingBar containerClassName="loading-bar-container" className="loading-bar" color='#f11946' ref={loadingBarReference} style={{position: "relative"}}/>
                    { /* https://github.com/jbetancur/react-data-table-component/blob/next/src/DataTable/styles.ts */ }
                   
                    <DataTable noHeader pagination responsive paginationServer
                           columns={columns} sortIcon={<ChevronDown />} data={configuration.rows} paginationComponentOptions={configuration.paginationOption}
                           onSort={handlerSort} sortServer persistTableHead defaultSortFieldId={3} progressPending={configuration.isLoading} progressComponent={<DataTableLoader />}
                           noDataComponent={configuration.message} disabled={configuration.disabled} paginationTotalRows={configuration.totalRows}
                           onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} paginationPerPage={defaultPaginationPerPage}
                           paginationRowsPerPageOptions={defaultPaginationNumber} highlightOnHover={true} pointerOnHover={true} onRowClicked={handlerRowClick}
                           customStyles={{
                               rows: { style: {fontSize: '14px'}, highlightOnHoverStyle: { backgroundColor: '#f9f9f9', borderBottomColor: '#E3E3E3FF', outlineColor: '#E3E3E3FF'}},
                               cells: { style: {wordBreak: 'break-word'} }
                           }}
                           noTableHead={isMobileOnly}
                           paginationDefaultPage={configuration.tableConfig.pagination.page}
                    />
                    { /* pagination info for mobile */}
                    { isMobileOnly && (() => {
                            const start = (configuration.tableConfig.pagination.page - 1) * configuration.tableConfig.pagination.perPage;
                            const end = start + configuration.tableConfig.pagination.perPage;
                            return <div style={{backgroundColor: 'white', padding: '10px 10px', borderTop: '1px solid #d9d9d9', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                <div>
                                    {i18next.t('pagination.page')}: {configuration.tableConfig.pagination.page}
                                </div>
                                <div>
                                    {i18next.t('pagination.rows')}&nbsp;
                                    {start + 1}-{(end > configuration.totalRows ? configuration.totalRows : end)}
                                    &nbsp;{i18next.t('pagination.page_separator')}&nbsp;
                                    {configuration.totalRows}
                                </div>
                            </div>
                        })()
                    }
                </div>
                { /* delete modal */ }
                <Modal isOpen={configuration.modal.open} toggle={handlerCloseDeleteModal}>
                    <ModalHeader toggle={handlerCloseDeleteModal}>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", alignContent: "center", justifyContent: "flex-start"}}>
                            <Trash size={"16"} />&nbsp;{t('delete')}
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <div>{t('modals.delete')}</div>
                        <div class={"mb-1"}/>
                        <div>{configuration.modal.selected.plant_name} ({configuration.modal.selected.plant_town_name})</div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handlerTrash}>
                            {t('accept')}
                        </Button>
                        <Button color="secondary" onClick={handlerCloseDeleteModal}>
                           {t('cancel')}
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* overlay loader for a card */ }
                { configuration.isLoading && <CardOverlayLoader />}
            </Card>
        </span>
    );
}
