import React from 'react';
//https://react-google-maps-api-docs.netlify.app/
import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api';
import {useTranslation} from "react-i18next";
import Skeleton from "react-loading-skeleton";
import {CardHeaderNoIcon} from "components/Cards/CardHeaderWithIcon";
import {getLanguage} from "utils/Language";
import config from "/app/src/config.js";

const isMobileOnly = config.mbl_version;

export function CardMaps({...props}) {
    const {t} = useTranslation();
    const coords = { lat: 0, lng: 0};

    if(props.coordinates) {
        const coordinates = props.coordinates.split(',');
        coords.lat = parseFloat(coordinates[0]);
        coords.lng = parseFloat(coordinates[1]);
    }

    return (
        <div className={`card card-statistics ${isMobileOnly && "card-mbl"}`}>
            {/*<CardHeaderNoIcon title={t('localization')} />*/}
            <div id="google-maps-container" className={`card-body statistics-body maps-wrapper-selector ${isMobileOnly && "card-body-mbl card-body-map-mbl"}`}>
                { !props.coordinates && <Skeleton style={{ height: '100%', width: '100%' }}/> }
                { props.coordinates &&
                <div  style={{height: '100%', width: '100%', overflow: "hidden", border: "1px solid #e7e5e5", minHeight: "300px"}}>
                    <LoadScript googleMapsApiKey="AIzaSyBoQCFa1z2vV7POcAGRYbW6w6jhm6Pl9jM" language={getLanguage()}>
                        <GoogleMap mapContainerClassName={`map-container`}
                                   mapContainerStyle={{ height: '110%', width: '100%', minHeight: '350px'}}
                                   center={{ lat: coords.lat, lng: coords.lng }} zoom={17}
                                   mapTypeId={'CUSTOM'}
                                   options={{
                                       streetViewControl: false,
                                       controlSize: 25,
                                       keyboardShortcuts: false,
                                       styles: [{
                                           featureType: "poi",
                                           elementType: "labels",
                                           stylers: [
                                               { visibility: "off" }
                                           ]
                                       }],
                                       zoomControlOptions: {
                                           position: 7.0,
                                       }
                                   }}>
                            <Marker position={{ lat: coords.lat, lng: coords.lng }}
                                    icon={{
                                        path: "M18.364 17.364L12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zM12 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z",
                                        fillColor: "red", fillOpacity: 0.9, scale: 1,
                                        strokeWeight: 0, anchor: {x: 23/2, y: 23}
                                    }} />
                        </GoogleMap>
                    </LoadScript>
                    <style>{`
                        #google-maps-container .map-container .gm-style .gmnoprint {
                          display: block !important;
                        }
                        #google-maps-container .map-container .gm-style .gmnoprint .gm-style-mtc {
                          display: block !important;
                        }
                      `}</style>
                </div>
                }
            </div>
        </div>
    );
}